@import "./mixin/mixin";
@import "./variables/fonts";
@import "./variables/colors";

* {
  margin: 0;
  padding: 0;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(187, 194, 201, 0.70);
  border-radius: 5px;
}


body {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .layout {
    display: -webkit-box;
  }

  .alert_block {
    width: 330px;
    position: absolute;
    right: 0;
    z-index: 150000;
    margin-right: 16px;

    &.warning {
      margin-right: 8px;
    }
  }

  .paper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .paper-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .paper_content {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .paper_title {
        font-size: $title;
        color: $periwinkle_blue;
        font-weight: 500;
        text-align: center;
      }

      .paper_paragraph {
        font-size: $small;
        color: $text_dark;
        font-weight: 400;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 5px;
      }
    }

    .paper_footer {
      text-align: center;

      .footer_paragraph {
        margin-top: 40px;
        color: $dark_grey;
        font-size: $small;
      }
    }

  }

  .paper_header_icon {
    background-image: url("../styles/images/paper.png");
    background-size: 16px;
    background-position: 7px 7px;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 50%;
    background-color: #80a1fd;
    margin: 0 16px 0 8px;
    border: 2px solid #e6e9eb;
  }

  .paper_icon {
    background-image: url("../styles/images/paper.png");
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: flex;
  }

  .bluip-logo {
    cursor: pointer;
    background-image: url("images/bluip-logo.svg");
    background-size: 70px;
    background-position: center;
    background-repeat: no-repeat;
    width: 70px;
    height: 30px;
    display: flex;
    @media (max-width: 600px) {
      margin-right: 0;
      width: 60px;
      height: 20px;
      padding: 0 10px;
    }
  }

  .bluip-logo_header {
    cursor: pointer;
    background-image: url("images/bluip-logo.svg");
    background-size: 60px;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    height: 30px;
    display: flex;
    margin-left: 24px;
  }

  .menu_icon {
    background-image: url("../styles/images/icons-about.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
    display: flex;
  }

  .phone_icon {
    background-image: url("../styles/images/voice-call.svg");
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: flex;
  }


  .list_name {
    color: $white;
  }

  .global_header {
    padding: 10px;
    border-bottom: 1px solid $cornflower;
    @include flex(row, space-between, center);
  }

  .did_header {
    @include flex(row, start, center);
    margin: 4px 8px;
    padding: 8px;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;

    .did_header-icon {
      background-image: url("../styles/images/voice-call.svg");
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      display: flex;
      border-radius: 50%;
      background-color: #80a1fd;
      margin: 0 16px 0 8px;
      border: 2px solid #e6e9eb;
    }

    .did_header-title {
      font-size: small;
      line-height: 2.42;
      color: $text_dark;
    }
  }

  .add_did_section {
    height: calc(100vh - 120px);

    .grid_block {
      width: 100%;
      height: 100%;
      @include flex(column, space-between, flex-end);

      .info_container {
        padding: 10px 20px;
      }

      .grid_container {
        margin: 4px 8px;
        // padding: 10px 20px;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        width: calc(100% - 17px);
        height: 90%;
        overflow-y: scroll;
        overflow-x: hidden;
        @media (max-width: 600px) {
          height: 85%;
        }

        .grid_container_title {
          margin-bottom: 20px;
          font-weight: 600;
          line-height: 1.81;
          color: $text_dark;
        }

        .grid_container_checkbox {
          display: flex;
          flex-direction: row;
          @media (max-width: 812px) {
            flex-direction: column;
          }
        }

        .grid_general_information_container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0 40px;
          margin-bottom: 40px;
          @media (max-width: 1189px) {
            grid-template-columns: repeat(2, 1fr)
          }
          @media (max-width: 648px) {
            grid-template-columns: repeat(1, 100%);
          }
          padding-right: 16px;

          .add_did_block {
            max-width: 470px;
            @include flex(column, flex-start, flex-start);
            margin-bottom: 16px;
            position: relative;
            @media (min-width: 1900px) {
              max-width: 600px;
            }

            .required_asterisk {
              color: #d32f2f;
              padding-left: 3px;
            }
          }
        }

        .grid_services_container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0 40px;
          margin-bottom: 40px;
          margin-top: 20px;
          @media (max-width: 1189px) {
            grid-template-columns: repeat(2, 1fr)
          }
          @media (max-width: 648px) {
            grid-template-columns: repeat(1, 100%);
          }
          padding-right: 16px;

          .add_did_block {
            max-width: 470px;
            @include flex(column, flex-start, flex-start);
            margin-bottom: 16px;
            position: relative;
            @media (min-width: 1900px) {
              max-width: 600px;
            }

            .required_asterisk {
              color: #d32f2f;
              padding-left: 3px;
            }
          }
        }

        .grid_broadsoft_container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0 40px;
          margin-bottom: 40px;
          @media (max-width: 1189px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (max-width: 1806px) {
            //grid-template-columns: repeat(3, 1fr)
          }
          @media (max-width: 648px) {
            grid-template-columns: repeat(1, 100%);
          }
          padding-right: 16px;

          .add_did_block {
            max-width: 470px;
            @include flex(column, flex-start, flex-start);
            margin-bottom: 16px;
            position: relative;
            @media (min-width: 1900px) {
              max-width: 600px;
            }

            .required_asterisk {
              color: #d32f2f;
              padding-left: 3px;
            }
          }
        }
      }

      .button_box {
        @include flex(row, flex-end, center);
        margin-right: 40px;
        height: 9%;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (max-width: 600px) {
          margin-right: 20px;
        }
      }
    }
  }

  .exception_section_box {
    height: calc(100vh - 150px);

    .exception_section {
      justify-content: flex-start;

      .exception_block {
        @include flex(column, flex-start, center);
        width: 100%;
        height: 100%;

        .content_grid {
          width: 100%;
          display: grid;
          grid-template: 1fr / 1fr;
          place-items: center;
          align-items: start;
          margin-top: 80px;
          height: -webkit-fill-available;

          .content_grid_child {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            width: 100%;
            height: -webkit-fill-available;
            @include flex(column, space-between, center);
          }
        }

        .devider {
          display: flex;
        }

        .exception_header {
          @include flex(row, space-between, flex-start);
          width: 100%;

          .icon_info {
            background-image: url("../styles/images/icons-info.svg");
            background-size: contain;
            background-position: top;
            background-repeat: no-repeat;
            width: 38px;
            height: 38px;
            display: flex;
          }

          .exception_title {
            font-size: $medium;
            color: $text_dark;
            max-width: 1010px;
          }

          .icon_more {
            background-image: url("../styles/images/icons-more.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 24px;
            height: 30px;
            display: flex;
            cursor: pointer;
          }
        }

        .stepper {
          margin-top: 40px;
          max-width: 1000px;
          width: 100%;
        }

        .step_one_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media (max-width: 768px) {
            align-items: self-start;
          }

          .step_one_title {
            color: $text_dark;
            font-size: $medium;
            text-align: center;
            max-width: 565px;
            @media (max-width: 768px) {
              text-align: left;
            }
          }

          .step_one_input {
            margin-top: 50px;
            max-width: 500px;
            width: 100%;
            @media (max-width: 768px) {
              max-width: 565px;
            }
          }
        }

        .step_two_container {
          width: 100%;
          height: -webkit-fill-available;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          @media (max-width: 768px) {
            align-items: self-start;
          }

          .box {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media (max-width: 768px) {
              align-items: self-start;
            }
          }
        }

        .step_three_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .icon_check {
            background-image: url("../styles/images/check.png");
            background-size: 48px;
            background-position: center;
            background-repeat: no-repeat;
            width: 48px;
            height: 48px;
            display: flex;
            margin-bottom: 24px;
          }

          .step_three_title {
            color: $text_dark;
            font-size: $medium;
            text-align: center;
            max-width: 410px;
            margin-bottom: 24px;
          }

          .step_three_error {
            color: $watermelon;
            font-size: $medium;
            text-align: center;
          }
        }

        .step_one_paragraph {
          text-align: center;
          font-size: $medium;
          color: $text_dark;
          max-width: 700px;
          @media (max-width: 768px) {
            text-align: left;
          }
        }

        .step_one_paragraph-upload {
          text-align: center;
          font-size: $medium;
          color: $text_dark;
          max-width: 440px;
          @media (max-width: 768px) {
            text-align: left;
          }
        }

      }

    }
  }

  .button_box {
    @include flex(row, flex-end, center);
    margin-right: 23px;
    gap: 12px;
  }


  .details_block_divider {
    height: 100%;

    .tabs_block {
      padding-left: 8px;
    }

    .history_title_block {
      width: 100%;
      @include flex(column, space-between, center);
      flex-direction: row-reverse;
      @media (max-width: 1189px) {
        margin-bottom: 0px;
      }
    }

    .details_block {
      @include flex(column, flex-start, flex-start);
      width: 100%;
      height: 100%;
      grid-gap: 16px;
    }

    .services_brodsoft_block {
      display: flex;
      width: 100%;

      .details_broadsoft_block {
        grid-gap: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .details_services_block {
        grid-gap: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

    }

    .did_details_divider {
      margin-bottom: 20px;

      .did_details_title {
        font-size: 13px;
        color: $dark_grey;
        line-height: 1.5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 600px) {
          line-height: 1;
        }
      }

      .did_details_paragraph {
        font-size: $medium;
        line-height: 1.9;
        color: $text_dark;
      }

      .no_data {
        font-size: $medium;
        line-height: 1.9;
        color: #b4bac2;
      }
    }

    .general_title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.81;
      color: $text_dark;
    }
  }

  .did_code {
    font-size: $medium;
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .container_header_block {
      @include flex(row, space-between, center);
      padding-bottom: 14px;
      @media (max-width: 715px) {
        @include flex(column, space-between, flex-start);
        padding-bottom: 14px;
        gap: 20px;
      }

      .search_block {
        @include flex(row, space-between, center);
        gap: 20px;

        .searchInput {
          width: 210px;
        }

        .search_icon {
          background-image: url("../styles/images/icons-search.svg");
          background-size: 24px;
          background-position: center;
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          display: flex;
        }

        .close_icon {
          background-image: url("../styles/images/close.svg");
          background-size: 10px;
          background-position: center;
          background-repeat: no-repeat;
          width: 10px;
          height: 10px;
          margin-right: 12px;
          cursor: pointer;
        }

        .advanced_button_block {
          display: flex;
          gap: 2px;
          height: 37px;

          .search_clear_icon {
            background-image: url("../styles/images/close_white.svg");
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;
            display: flex;
          }
        }

      }

      .add_did_block {
        @include flex(row, flex-end, center);
        @media (max-width: 600px) {
          margin-top: 10px;
        }

        .filter_button {
          padding: 0 0 0 16px;

          .filter_icon {
            background-image: url("../styles/images/icons-filter.svg");
            background-size: 24px;
            background-position: center;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            display: flex;
          }


        }

        .bulk_update_popover {
          @include flex(row, center, center);
          padding-left: 12px;

          .more_vert_icon {
            cursor: pointer;
          }
        }

      }
    }
.table_block_applied_search{
  height: 92%;
  display: flex;
  flex-direction: column;
  &--separator{
    @include flex(column, space-between, initial);
    height: 100%;
  }
  @media (max-width: 715px) {
    height: 87%;
  }
}
    .table_block {
      height: 92%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-shadow: none;
      @media (max-width: 715px) {
        height: 87%;
      }

      .more_vert_box {
        display: flex;
        justify-content: center;

        .more_vert_icon {
          font-size: 1rem;
        }
      }
    }

    .table_paper_applied_search {
      min-height: 80px;
      height: inherit;
      box-shadow: none;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .table_paper {
      @include flex(column, space-between, end);
      box-shadow: 0 0 0 0;
      height: 100%;

      .arrow {
        background-image: url("../styles/images/arrow.svg");
        background-size: 10px;
        background-position: center;
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }

      .rotate-arrow {
        transform: rotate(180deg);
      }
    }

    .empty_table_block {
      @include flex(column, center, center);
      position: absolute;
      top: 50%;
      left: 50%;

      .no-result-icon {
        background-image: url("../styles/images/icons-search.svg");
        background-size: 50px;
        background-position: center;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        display: flex;
      }

      .empty_table {
        font-size: $medium;
        line-height: 2.42;
        color: #3f4244;
      }
    }
  }

  .dialog_block {
    border-top: 1px solid #80a1fd;
    height: fit-content;
    padding: 12px 0;
    min-height: 120px;

    .dialog_paragraph {
      font-size: 13px;
      color: #707780;
      line-height: 1.6;
      text-align: start;

      &:first-child {
        margin-bottom: 18px;
      }
    }
  }

  .filter_block {
    width: 320px;
    overflow: hidden;

    .filter_title {
      font-size: $medium;
      font-weight: 600;
      color: $text_dark;
      padding: 14px;
      border-bottom: 1px solid rgba(180, 186, 194, 0.3);;
    }

    .filter_form_control {
      padding: 6px 12px 0 12px;
      max-height: 450px;
      overflow-y: scroll;
      margin-bottom: 14px;

      .add_did_block {
        margin-bottom: 10px;
      }
    }

    .filter_box_button {
      @include flex(row, end, center);
      padding: 14px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
      border-radius: 2px;
    }

    .data_picker_block {
      @include flex(column, center, end);
      margin-bottom: 12px;

      .data_picker_block_divider {
        @include flex(row, space-between, center);
        gap: 12px;
      }

    }

    .date_error {
      color: $watermelon;
      font-size: 12px;
    }

    .calendar_icon {
      background-image: url("../styles/images/calendar.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      display: flex;

    }
  }

  .drag_drop_block {
    @include flex(column, center, center);
    margin-bottom: 12px;
    border: 1px dashed transparent;
    padding: 20px;
    width: 100%;
    z-index: 0;
    @media (min-width: 1900px) {
      margin-top: 5%;
    }

    .drag_block {
      @include flex(column, center, center);
      width: 100%;
    }

    &.dragging {
      border: 1px dashed #9e9e9e;
      width: 100%;
    }

    .drag_drop_icon {
      background-image: url("../styles/images/drag_drop_icon.svg");
      background-size: 70px;
      background-position: center;
      background-repeat: no-repeat;
      width: 70px;
      height: 70px;
      margin-bottom: 12px;
    }

    .drag_drop_icon_exception {
      background-image: url("../styles/images/file.png");
      background-size: 40px;
      background-position: center;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-bottom: 12px;
      margin-left: 18px;
      margin-top: 28px;
    }

    .drag_drop_link {
      font-size: $medium;
      color: $periwinkle_blue;
    }

    .drag_drop_title {
      font-size: $medium;
      color: $text_dark;
    }

    .upload_type {
      display: none;
    }

    .download_icon {
      background-image: url("../styles/images/import.svg");
      background-size: 60px;
      background-position: center;
      background-repeat: no-repeat;
      width: 60px;
      height: 60px;
      margin-bottom: 12px;
    }

    .download_icon_exception {
      background-image: url("../styles/images/import.svg");
      background-size: 40px;
      background-position: center;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-bottom: 12px;
      margin-top: 28px;
    }

    .file_download_text {
      font-size: 14px;
      color: $text_dark;
    }

    .file_download_span {
      font-size: 14px;
      color: $dark_grey;
      margin-bottom: 20px;
    }
  }

  .download_block {
    position: absolute;
    bottom: 10px;
    width: -webkit-fill-available;
    margin-right: 12px;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 14px 36px 14px 25px;
    border-radius: 4px;
    border: solid 1px rgba(128, 161, 253, 0.5);
    background-color: rgba(128, 161, 253, 0.09);

    .progress_icon {
      background-image: url("../styles/images/status-loading.svg");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-right: 9px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .file_download_text_progress {
      font-size: 14px;
      color: $text_dark;
    }
  }

  .download_block_exception {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 14px 36px 14px 25px;
    border-radius: 4px;
    border: solid 1px rgba(128, 161, 253, 0.5);
    background-color: rgba(128, 161, 253, 0.09);

    .progress_icon {
      background-image: url("../styles/images/status-loading.svg");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-right: 9px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .file_download_text_progress {
      font-size: 14px;
      color: $text_dark;
    }
  }

  .file_error_block {
    width: -webkit-fill-available;

    .error_icon {
      background-image: url("../styles/images/icons-error-copy.svg");
      background-size: 40px;
      background-position: center;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 9px;
    }

  }

  .file_text {
    font-size: 14px;
    color: $text_dark;
    line-height: 2.7;
  }

  .excel_block {
    height: 100%;

    .template_header {
      @include flex(column, inherit, start);

      .template_title {
        font-size: 15px;
        line-height: 1.81;
        color: $text_dark;
      }
    }

    .template_content {
      @include flex(row, start, center);

      .excel_icon {
        background-image: url("../styles/images/excel.svg");
        background-size: 70px;
        background-position: center;
        background-repeat: no-repeat;
        width: 70px;
        height: 70px;
        margin-right: 16px;
      }

      .content_title {
        font-size: $medium;
        line-height: 1.71;
        color: $text_dark;
      }

      .content_span {
        font-size: $medium;
        line-height: 1.71;
        color: $dark_grey;
      }
    }

    .container_main {
      @include flex(column, space-between, inherit);
      height: 60%;

      .container_main_separator {
        @include flex(row, space-between, end);
        @media (max-width: 600px) {
          @include flex(column, space-between, start);
          height: 22%;
        }

        .file_title {
          font-size: 15px;
          line-height: 1.81;
          color: $text_dark;
        }
      }
    }

    .note_block {
      @include flex(row, start, center);

      .note_icon {
        background-image: url("../styles/images/icons-note.png");
        background-size: 18px;
        background-position: left;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        display: flex;
      }

      .file_span {
        font-size: 10px;
        line-height: 1.81;
        color: $periwinkle_blue;
      }
    }

    .container_file_size {
      position: absolute;
      bottom: 10px;
      width: -webkit-fill-available;
      margin-right: 12px;
    }


    .file_span {
      font-size: 13px;
      color: $text_dark;
    }

    .file_span_bold {
      font-size: 13px;
      color: $text_dark;

    }


    .file_warning_block {
      .warning_icon {
        background-image: url("../styles/images/warning.svg");
        background-size: 40px;
        background-position: center;
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        margin-right: 9px;
      }

    }

    .file_warning_block[true] ~ .file_box {
      display: none;
    }


    .file_box {
      overflow-y: scroll;
      max-height: 35%;
      display: grid;
      align-items: end;
    }


    .button_box {
      @include flex(row, flex-end, center);
      margin-right: 23px;
      gap: 12px;
    }
  }


  .progress_block {
    @include flex(row, center, center);
    margin-top: 200px;
  }

  .pagination_footer {
    @include flex(row, flex-end, center);
  }

}

.redirect_block {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.hide, .hide-arrow {
  opacity: 0;
}

.show:hover .hide, .showSortedColumnArrow {
  opacity: 1
}

.no-results-column:hover {
  cursor: default !important;
  background-color: inherit !important;
}

.cross_icon {
  background-image: url("../styles/images/cross.svg");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: flex;
}

.disabledPartnerCustomer {
  color: #ee6723 !important;
}

.loadingCustomers {
  color: #C8C8C8 !important;
}

.drop_down_arrow {
  background-image: url("../styles/images/arrow.svg");
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  display: flex;
}

.loading_icon_block {
  bottom: 0;
  position: fixed;
  height: 28px;
  width: calc(100% - 5px);
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading_icon {
    background-image: url("../styles/images/status-loading.svg");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading_icon_exception {
  background-image: url("../styles/images/status-loading.svg");
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px !important;
  width: 40px !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin-bottom: 24px;
}


.search_textarea {
  resize: vertical;
  width: 100%;
  height: 240px;
  min-height: 62px;
  padding: 18px;
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  line-height: 24px;
  font-family: -apple-system, 'Segoe UI', 'Roboto', sans-serif;

  &::placeholder {
    font-family: -apple-system, 'Segoe UI', 'Roboto',
    sans-serif;
    letter-spacing: 0.2px;
  }

  &:focus-visible {
    //border: 1px solid $periwinkle_blue;
    outline: $periwinkle_blue auto 0.5px;
  }
}

.advancedSearchApplied {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
