
@mixin flex($direction, $js_cont, $align_item) {
  flex-direction: $direction;
  justify-content: $js_cont;
  display: flex;
  align-items: $align_item;
}

@mixin bgk_image($background_image,$bgk_size, $bgk_pos){
  background-image: $background_image;
  background-size: $bgk_size;
  background-repeat: no-repeat;
  background-position: $bgk_pos;

  
}
